.maxImage {
    max-width: 100%;
}

.cardHeadTitle {
    color: #767a7e;
	text-align: left;
	margin-top: 15px;
	font-size: 1.1em;
	min-height: auto;
	font-family: 'Roboto','Helvetica','Arial',sans-serif;
	text-decoration: none;
	font-weight: 500;
}

.cardNoContent {
    text-align: center;
    margin-top: 30%;
    line-height: 2;
}

.cardHeadContent {
    color: #3C4858;
    font-weight: 700;
    &::after {
        content: '';
        padding-bottom: 10px;
        border-bottom: 1px solid #f1f1f1;
        width: 100%;
        display: block;
    }
}
.buttonWarnings {
    padding: 7px 15px !important;
    position: absolute !important;
    bottom: 0;
    left: 15%;

}

@media screen and (max-width: 480px) {
    .buttonWarnings {
        font-size: 12px !important;
        bottom: inherit;
        left: inherit;
        top: 0;
        right: 5%;
    }
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
    .buttonWarnings {
        bottom: inherit;
        left: inherit;
        top: 0;
        right: 5%;
    }
    .cardHeadTitle {
        text-align: right;
        font-size: 1.05em;
    }
}

.cardBodyTitle {
    font-size: 3em;
    text-align: center;
    color: #3C4858;
    font-family: 'Arial Normal', 'Arial';
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    border-top: 1px solid #eee;
    padding-top: 25px;
}

.cardBodyValue {
    font-size: 30px;
    font-family: 'Arial Normal', 'Arial';
    font-weight: 'bold';
    font-style: normal;
    color: #3C4858;
    text-align: left;
    line-height: normal;
}
