$ct-series-colors: (
    #d70206,
    #f05b4f,
    #f4c63d,
    #d17905,
    #453d3f,
    #59922b,
    #0544d3,
    #6b0392,
    #f05b4f,
    #dda458,
    #eacf7d,
    #86797d,
    #b2c326,
    #6188e2,
    #a748ca
) !default;

.ct-legend {
    position: relative;
    z-index: 10;
    cursor: pointer;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 23px;
        margin-left: 5px;
        display: inline;
    }

    li:hover {
        opacity:0.8;
    }

    li:before {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        content: '';
        border: 3px solid transparent;
        border-radius: 50%;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: 30px;
    }

    @for $i from 0 to length($ct-series-colors) {
        .ct-series-#{$i}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
    }

    @media screen and (max-width: 768px) {
        .ct-legend {
            li {
                display: inherit;
            }
        }
    }
}