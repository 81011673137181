.breadcrumb {
    text-transform: initial;
    color: #717171 !important;;
}

@media screen and (max-width: 599px) {
    .breadcrumb {
        display: none !important;
    }
} 

@media screen and (min-width: 600px) and (max-width: 959px) {
    .breadcrumb {
        margin-left: 15px !important;
    }
}