.sectionTitle {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
  color: #3c4858;
  // line-height: 2.6em;
  span::before {
    color: #3c4858;
  }
}
