@font-face {
  font-family: 'B2K';
  src:  url('fonts/B2K.eot?822kuk');
  src:  url('fonts/B2K.eot?822kuk#iefix') format('embedded-opentype'),
    url('fonts/B2K.ttf?822kuk') format('truetype'),
    url('fonts/B2K.woff?822kuk') format('woff'),
    url('fonts/B2K.svg?822kuk#B2K') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'B2K' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-informacao:before {
  content: "\e98a";
}
.icon-ico_excluir:before {
  content: "\e989";
}
.icon-email_add:before {
  content: "\e988";
}
.icon-agrupamento_estacoes:before {
  content: "\e987";
}
.icon-ico_referencias:before {
  content: "\e986";
}
.icon-ico_whatsapp:before {
  content: "\e985";
}
.icon-sinal_wifi:before {
  content: "\e984";
}
.icon-exclamacao:before {
  content: "\e915";
}
.icon-enviar:before {
  content: "\e917";
}
.icon-desativado:before {
  content: "\e962";
}
.icon-offline_manual:before {
  content: "\e973";
}
.icon-offline:before {
  content: "\e974";
}
.icon-critico:before {
  content: "\e975";
}
.icon-atencao:before {
  content: "\e976";
}
.icon-ativo:before {
  content: "\e977";
}
.icon-add_estacao:before {
  content: "\e978";
}
.icon-remover_estacao:before {
  content: "\e979";
}
.icon-configuracao:before {
  content: "\e97a";
}
.icon-mensagens:before {
  content: "\e97b";
}
.icon-calendario:before {
  content: "\e97c";
}
.icon-envios:before {
  content: "\e97d";
}
.icon-exportar:before {
  content: "\e97e";
}
.icon-rascunho:before {
  content: "\e97f";
}
.icon-arquivar:before {
  content: "\e980";
}
.icon-Tab_lido:before {
  content: "\e981";
}
.icon-Tab_naoLido:before {
  content: "\e982";
}
.icon-qualidade_dados:before {
  content: "\e983";
}
.icon-ico_add_estacao:before {
  content: "\e912";
  color: #fff;
}
.icon-estacao:before {
  content: "\e971";
  color: #fff;
}
.icon-desvincular_prorietario:before {
  content: "\e972";
  color: #fff;
}
.icon-bot_carregar_firmware:before {
  content: "\e970";
  color: #fff;
}
.icon-importar:before {
  content: "\e96d";
  color: #fff;
}
.icon-associar:before {
  content: "\e96e";
  color: #fff;
}
.icon-senha:before {
  content: "\e96f";
  color: #fff;
}
.icon-reenviar_senha:before {
  content: "\e966";
  color: #fff;
}
.icon-ico_usuario_novo:before {
  content: "\e969";
  color: #fff;
}
.icon-ico_cliente:before {
  content: "\e96a";
  color: #fff;
}
.icon-ico_grupo_novo:before {
  content: "\e96b";
  color: #fff;
}
.icon-information:before {
  content: "\e96c";
}
.icon-ico_integracao:before {
  content: "\e916";
  color: #fff;
}
.icon-ico_firmware:before {
  content: "\e918";
  color: #fff;
}
.icon-ico_configuracoes:before {
  content: "\e91a";
  color: #fff;
}
.icon-ico_dashboard:before {
  content: "\e914";
  color: #fff;
}
.icon-check:before {
  content: "\e967";
  color: #fefefe;
}
.icon-vincular:before {
  content: "\e968";
  color: #fefefe;
}
.icon-adicionar:before {
  content: "\e95f";
  color: #fefefe;
}
.icon-cancelar:before {
  content: "\e960";
  color: #fefefe;
}
.icon-salvar:before {
  content: "\e961";
  color: #fefefe;
}
.icon-voltar:before {
  content: "\e963";
  color: #fefefe;
}
.icon-editar:before {
  content: "\e964";
  color: #fefefe;
}
.icon-add_cliente:before {
  content: "\e965";
  color: #fefefe;
}
.icon-logo_B2K .path1:before {
  content: "\e957";
  color: rgb(104, 157, 202);
}
.icon-logo_B2K .path2:before {
  content: "\e958";
  margin-left: -4.3212890625em;
  color: rgb(254, 254, 254);
}
.icon-logo_B2K .path3:before {
  content: "\e959";
  margin-left: -4.3212890625em;
  color: rgb(254, 254, 254);
}
.icon-logo_B2K .path4:before {
  content: "\e95a";
  margin-left: -4.3212890625em;
  color: rgb(254, 254, 254);
}
.icon-logo_B2K .path5:before {
  content: "\e95b";
  margin-left: -4.3212890625em;
  color: rgb(254, 254, 254);
}
.icon-logo_B2K .path6:before {
  content: "\e95c";
  margin-left: -4.3212890625em;
  color: rgb(254, 254, 254);
}
.icon-logo_B2K .path7:before {
  content: "\e95d";
  margin-left: -4.3212890625em;
  color: rgb(254, 254, 254);
}
.icon-logo_B2K .path8:before {
  content: "\e95e";
  margin-left: -4.3212890625em;
  color: rgb(254, 254, 254);
}
.icon-seta:before {
  content: "\e919";
  color: #fefefe;
}
.icon-loading:before {
  content: "\e952";
}
.icon-exit:before {
  content: "\e953";
  color: #fefefe;
}
.icon-carregar:before {
  content: "\e954";
}
.icon-bateria:before {
  content: "\e955";
}
.icon-atualizar:before {
  content: "\e956";
  color: #fefefe;
}
.icon-logo_beMetrics_branca .path1:before {
  content: "\e93e";
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path2:before {
  content: "\e93f";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path3:before {
  content: "\e940";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path4:before {
  content: "\e941";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path5:before {
  content: "\e942";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path6:before {
  content: "\e943";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path7:before {
  content: "\e944";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path8:before {
  content: "\e945";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path9:before {
  content: "\e946";
  margin-left: -4.7841796875em;
  color: rgb(254, 254, 254);
}
.icon-logo_beMetrics_branca .path10:before {
  content: "\e947";
  margin-left: -4.7841796875em;
  color: rgb(0, 135, 191);
}
.icon-logo_beWeather_branca .path1:before {
  content: "\e948";
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path2:before {
  content: "\e949";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path3:before {
  content: "\e94a";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path4:before {
  content: "\e94b";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path5:before {
  content: "\e94c";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path6:before {
  content: "\e94d";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path7:before {
  content: "\e94e";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path8:before {
  content: "\e94f";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-logo_beWeather_branca .path9:before {
  content: "\e950";
  margin-left: -5.291015625em;
  color: rgb(0, 135, 191);
}
.icon-logo_beWeather_branca .path10:before {
  content: "\e951";
  margin-left: -5.291015625em;
  color: rgb(254, 254, 254);
}
.icon-Logo_SAP .path1:before {
  content: "\e900";
  color: rgb(26, 120, 210);
}
.icon-Logo_SAP .path2:before {
  content: "\e901";
  margin-left: -2.0224609375em;
  color: rgb(255, 255, 255);
}
.icon-BeMe_APP_Cinza .path1:before {
  content: "\e902";
  color: rgb(239, 239, 240);
}
.icon-BeMe_APP_Cinza .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(49, 47, 50);
}
.icon-BeMe_APP_Cinza .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(49, 47, 50);
}
.icon-BeMe_APP_Cinza .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(0, 135, 191);
}
.icon-BeMe_APP_Cinza1 .path1:before {
  content: "\e906";
  color: rgb(239, 239, 240);
}
.icon-BeMe_APP_Cinza1 .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(44, 42, 44);
}
.icon-BeMe_APP_Cinza1 .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(44, 42, 44);
}
.icon-BeMe_APP_Cinza1 .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 135, 191);
}
.icon-beWe_icone:before {
  content: "\e90a";
  color: #fefefe;
}
.icon-bot_busca:before {
  content: "\e90b";
  color: #fefefe;
}
.icon-bot_close:before {
  content: "\e90c";
  color: #fefefe;
}
.icon-bot_edit:before {
  content: "\e90d";
  color: #fefefe;
}
.icon-bot_pin:before {
  content: "\e90e";
  color: #fefefe;
}
.icon-check1:before {
  content: "\e90f";
  color: #00a859;
}
.icon-ico_acesso:before {
  content: "\e910";
  color: #fefefe;
}
.icon-ico_acesso_grupo:before {
  content: "\e911";
  color: #fefefe;
}
.icon-ico_cadastro:before {
  content: "\e913";
  color: #fefefe;
}
.icon-ico_localizacao:before {
  content: "\e91b";
  color: #fefefe;
}
.icon-ico_menu:before {
  content: "\e91c";
  color: #fefefe;
}
.icon-logo_beMetrics .path1:before {
  content: "\e91d";
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path2:before {
  content: "\e91e";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path3:before {
  content: "\e91f";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path4:before {
  content: "\e920";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path5:before {
  content: "\e921";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path6:before {
  content: "\e922";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path7:before {
  content: "\e923";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path8:before {
  content: "\e924";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path9:before {
  content: "\e925";
  margin-left: -4.7802734375em;
  color: rgb(49, 47, 50);
}
.icon-logo_beMetrics .path10:before {
  content: "\e926";
  margin-left: -4.7802734375em;
  color: rgb(0, 135, 191);
}
.icon-logo_beWeather .path1:before {
  content: "\e927";
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path2:before {
  content: "\e928";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path3:before {
  content: "\e929";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path4:before {
  content: "\e92a";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path5:before {
  content: "\e92b";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path6:before {
  content: "\e92c";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path7:before {
  content: "\e92d";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path8:before {
  content: "\e92e";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_beWeather .path9:before {
  content: "\e92f";
  margin-left: -5.2861328125em;
  color: rgb(0, 135, 191);
}
.icon-logo_beWeather .path10:before {
  content: "\e930";
  margin-left: -5.2861328125em;
  color: rgb(49, 47, 50);
}
.icon-logo_eProdutor .path1:before {
  content: "\e931";
  color: rgb(156, 189, 94);
}
.icon-logo_eProdutor .path2:before {
  content: "\e932";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path3:before {
  content: "\e933";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path4:before {
  content: "\e934";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path5:before {
  content: "\e935";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path6:before {
  content: "\e936";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path7:before {
  content: "\e937";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path8:before {
  content: "\e938";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path9:before {
  content: "\e939";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-logo_eProdutor .path10:before {
  content: "\e93a";
  margin-left: -2.03515625em;
  color: rgb(0, 0, 0);
}
.icon-map:before {
  content: "\e93b";
  color: #fefefe;
}
.icon-User:before {
  content: "\e93c";
  color: #fefefe;
}
.icon-be_icone:before {
  content: "\e93d";
  color: #fefefe;
}
