$chart-tooltip-bg: #000;
$chart-tooltip-color: #fff;
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 3em;
  padding: 0.2em .5em;
  background: $chart-tooltip-bg;
  color: $chart-tooltip-color;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  pointer-events: none;
  z-index: 5;
  line-height: 1.2;
  margin-left: 20px;
  margin-top: 20px;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: $chart-tooltip-bg;
  }
  &.tooltip-show {
    opacity: 0.75;
  }
}

.ct-area, .ct-line {
  pointer-events: none;
}